import './Recipe.css';

import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import GoogleAd from '../../components/GoogleAd';

import IsAuthenticatedModal from '../isAuthenticationModal/IsAuthenticatedModal';
import clock from './clock-regular.svg';
import { ReactComponent as ReactHeart } from './heart.svg';
import { ReactComponent as ReactHeartFilled } from './heart_filled.svg';
import person from './user-solid.svg';
import ut_logo from '../../assets/img/ut_logo.png'
import iphone_ut from '../../assets/img/iphone_ut.png'
import ut_bg3 from '../../assets/img/ut-ad2-mobile2.jpg'
import ut_gif from '../../assets/img/ucuzatopla-ad-bg-gif2.gif'

const modalMessage = 'Tarifi favorilerinize ekleyebilmeniz için';

function Recipe(props) {
  const { favoriteIDs } = props;
  const isAuthenticated = localStorage.getItem('token');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleToggleFavorite = id => {
    if (isAuthenticated) {
      props.onToggleFavorite(id);
    } else {
      setShow(true);
    }
  };

  return (
    <><Col className="d-flex justify-content-center px-1 mb-3" lg={4} md={6} xs={6}>
      <div
        onClick={() => {
          handleToggleFavorite(props.dish.id);
        }}
        className="favorite-button"
      >
        {favoriteIDs !== undefined && favoriteIDs.find(favID => favID === props.dish.id) ? <ReactHeartFilled className="heart" /> : <ReactHeart className="heart" style={{ zIndex: '0' }} />}
      </div>

      <a
        style={{ width: '100%' }}
        href={props.dish.recipe_url}
        rel="noopener noreferrer"
        target="_blank"
        className="dish-a "
      >
        <Card style={{ width: '100%' }} className="dish mx-auto">
          {/* <Card.Img className="card-image-top" variant="top" src={props.dish.image_url} /> */}
          <img className="card-image-top" variant="top" src={props.dish.image_url} referrerPolicy='no-referer' alt='dish'/>
          <Card.Body>
            <div style={{ height: '15px' }}>
              <span className="float-left card-body-category">{props.dish.category_display_name}</span>
              <span className="float-right card-body-amount">{props.dish.item_list.length} Mlz.</span>
            </div>
            <Card.Title className="dish-card-title mt-1 mb-1">{props.dish.title}</Card.Title>
            <Card.Text className="dish-card-description">{props.dish.description}</Card.Text>
            <Row className='w-100 mx-auto my-1'>
              <Col className="col-6 pr-1 pl-0">
                <Card.Text className="dish-card-time">
                  <img className="mr-1" style={{ marginBottom: '2px' }} src={clock} alt="time" width="13px" height="auto" />
                  {props.dish.total_prep_time} Dakika
                </Card.Text>
              </Col>
              <Col className="col-6 pl-1 pr-0">
                <Card.Text className="dish-card-time">
                  <img className="mr-1" src={person} alt="person" width="11px" height="auto" />
                  {props.dish.for_person}
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </a>
      <IsAuthenticatedModal show={show} handleClose={handleClose} modalMessage={modalMessage} />
    </Col>
    { (props.idx + 1) % 10 === 0 && props.idx !== 0 && props.idx !== 19 &&
      <Col lg={4} md={6} xs={12} className='px-0' style={{ minHeight: '170px', position: 'relative' }}>
        <div className='h-100 pb-3 px-1' style={{ position: 'absolute', top: '0', width: '100%' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <GoogleAd className="wasteys-recipe-ad" dataAdSlot="6774911239" fullWidthResponsive="false" />
            <GoogleAd className="wasteys-recipe-ad-desktop" dataAdSlot="4203259224" fullWidthResponsive="false" />
          </div>
        </div>
      </Col>
    }    
    {/* { (props.idx === 9 || props.idx === 29 || props.idx === 49 || props.idx === 69  ) &&
      <Col xs={12} className='px-0 mb-3 recipe-ucuzatopla-ad-col px-1' style={{ height: '110px'}}>
        <a className='recipe-ucuzatopla-ad-mobile w-100 h-100 rounded shadow-sm' 
            href='https://ucuzatopla.com' target='_blank' rel="noreferrer"
            style={{ background: (props.idx === 9 || props.idx === 49) ? `url(${ut_gif})` : `url(${ut_bg3})` }}>
          <p>{(props.idx === 9 || props.idx === 49) ? '1. Mahalleni seç' : 'Favori marketlerin favori ürünlerin' }</p>
          <p>{(props.idx === 9 || props.idx === 49) ? '2. Mahallendeki en ucuz meyve sebzeyi bul' : <>Adresini seç <br/> sepetini doldur</> }</p>
          <p>{(props.idx === 9 || props.idx === 49) ? <>Kaliteyi uyguna <br/><strong>Ucuza Topla</strong></> : <>Güncel fiyatlar <br/><strong>Ucuza Topla'da</strong></> }</p>
          <div className={`button-div ${!(props.idx === 9 || props.idx === 49) && 'w-50'}`} style={{ background: (props.idx === 9 || props.idx === 49) ? '#F4A45A' : '#5A98F4' }}>ucuzatopla.com</div>
          {(props.idx === 9 || props.idx === 49) ? <img className='ut-logo' src={ut_logo} alt="ucuzatopla" height={40}/> : <img className='phone-ut' src={iphone_ut} alt="ucuzatopla" height={'95%'}/>}
        </a>
      </Col>
    }     */}
    {props.idx === 19 &&
      <Col xs={12} className='px-0 mb-3 recipe-ucuzatopla-ad-col px-1' style={{ height: '110px'}}>
        <a className='recipe-ucuzatopla-ad-mobile w-100 h-100 rounded shadow-sm' 
            href='https://ucuzatopla.com' target='_blank' rel="noreferrer"
            style={{ background: `url(${ut_bg3})` }}>
          <p>1. Mahalleni seç</p>
          <p>2. Mahallendeki en ucuz meyve sebzeyi bul</p>
          <p>Kaliteyi uyguna <br/><strong>Ucuza Topla</strong></p>
          <div className='button-div w-50' style={{ background: '#5A98F4' }}>ucuzatopla.com</div>
          <img className='phone-ut' src={iphone_ut} alt="ucuzatopla" height={'95%'}/>
        </a>
      </Col>
    }    
      </>
  );
}

export default Recipe;
