import '../assets/css/Layout.css';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

import Footer from '../components/footer/Footer';
import GoogleAd from '../components/GoogleAd';
import NavigationBar from '../components/navbar/Navbar';
import { logout } from '../store/actions/auth';

function CustomLayout(props) {
  const location = useLocation();
//   useEffect(() => {
//     try {
//         (window.adsbygoogle = window.adsbygoogle || []).push({});
//         (window.adsbygoogle = window.adsbygoogle || []).push({});
//         (window.adsbygoogle = window.adsbygoogle || []).push({});
//         (window.adsbygoogle = window.adsbygoogle || []).push({});
//     }
//     catch (e) {
//     }

// },[]);

  return (
    <HelmetProvider>
      <div className='wasteys-top-ad-div'>
        <GoogleAd className="wasteys-top-ad-mobile" dataAdSlot="5722147037"/>    
        <GoogleAd className="wasteys-top-ad-desktop" dataAdSlot="3287555384"/>    
      </div>
      <div className='app-container'>
        <div className="desktop-side-ad-div">
          <div>
            <GoogleAd className="wasteys-side-ad" dataAdSlot="6324550753"/>  
          </div>
        </div>
        <div className='app-content'>
          <NavigationBar />
          {props.children}
        </div>
        <div className="desktop-side-ad-div">
          <div>
            <GoogleAd className="wasteys-side-ad" dataAdSlot="9522431876"/>
          </div>
        </div>
      </div>      
      <Footer />
      <div className={location.pathname === '/' ? "d-none" : "d-block d-sm-none"} style={{ height: '110px' }} />
    </HelmetProvider>
  );
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  errors: state.errors,
  isAuthenticated: state.auth.token !== null,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout));
